<template>
  <div>

    <div data-animation="default" data-collapse="medium" data-duration="400" data-easing="ease" data-easing2="ease" role="banner" class="navbar-component _2 w-nav">
      <div class="navbar-container w-container">
        <a id="w-node-_2bc7849c-fc53-2a49-059f-aa960ee8e78d-0ee8e78b" @click="goToHome" class="navbar-logo-link no-rizer w-nav-brand"><img src="../../../../../public/images/kolobok.svg" loading="lazy" alt="" class="navbar-logo"></a>
      </div>
    </div>
    <div class="section color mid-line">
      <div data-w-id="8a817dec-33d8-ba64-0999-5e0ddc2f3a81" class="container last form one w-container">
        <div class="form-regidet w-form">
          <div class="wrap-steps">
            <a @click="previousPage" class="arrow-left w-inline-block"><img src="../../../../../public/images/Union.svg" loading="lazy" alt="" class="arrow"></a>
            <div class="step">
              <div class="step-number active">
                <div class="number-txt">1</div>
                <div data-w-id="d73203a7-f081-4301-5c74-acb811806f80" class="pulse"></div>
              </div>
            </div>
            <div class="step">
              <div class="step-number">
                <div>2</div>
              </div>
            </div>
            <div class="step">
              <div class="step-number">
                <div>3</div>
              </div>
            </div>
            <div class="step">
              <div class="step-number">
                <div>4</div>
              </div>
            </div>
          </div>
          <form id="wf-form-signup-2" name="wf-form-signup-2" data-name="signup" method="get" data-ms-form="signup" class="form-input-regidet" data-wf-page-id="66668e6af010f14082efc493" data-wf-element-id="d73203a7-f081-4301-5c74-acb811806f90">
            <v-form ref="form_phone_check" v-model="valid_phone_check" lazy-validation>
              <div class="line-progress step-phone"></div>
              <div class="tittle-form phone-sms">
                <h1 class="heading-form">Подтверждение номера</h1>
                <div
                    style="margin-bottom: 15px;"
                    class="text-classic-link">
                  На номер <span class="bold-phone">{{registration_data.profile.phone}}</span> отправлено SMS с кодом
                </div>
              </div>
              <div class="w-layout-vflex flex-form">
                <div class="w-layout-hflex flex-input">
                  <div class="input-box">
                    <label for="Phone-SMS" class="input-label">Введите код из SMS</label>
                    <v-text-field
                        outlined
                        type="text"
                        inputmode="numeric"
                        :rules="phoneCodeRules"
                        v-model="code"
                        maxlength="4"
                        v-mask="'####'"
                        :disabled="isCodeSend || timerExpire || disableCode"
                    ></v-text-field>
                    <div v-if="!timerExpire && !isCodeSend" class="w-layout-hflex time-inpur-form"><img src="../../../../../public/images/Outline_1.svg" loading="lazy" alt="">
                      <div class="time-phone-sms"><span class="bold-time-sms">{{ formattedTime }}</span> - Отправить код повторно</div>
                    </div>
                    <div v-if="timerExpire" class="w-layout-hflex time-inpur-form"><img src="../../../../../public/images/Outline_1.svg" loading="lazy" alt="">
                      <div class="time-phone-sms"><span class="bold-time-sms"></span> Время истекло</div>
                    </div>
                  </div>
                </div>
              </div>
              <a id="openLabel"
                 style="margin-top: 15px; width: 100%"
                 v-if="!timerExpire"
                 @click="sendCode"
                 :class="{ 'disable': isCodeSend || disableCode }"
                 class="button-bust w-button">
                Продолжить
              </a>
              <a
                  id="openLabel"
                  v-else
                  @click="reSendCode"
                  class="button-bust w-button"
                  style="margin-top: 15px; width: 100%">
                Повторить код
              </a>
              <link rel="prerender">
            </v-form>
          </form>

          <div v-if="isError" class="form-fail error notification">
            <div>Кажется, код неверный</div>
          </div>

          <div v-if="attempts === 0" class="form-fail error notification">
            <div>Попытки закончились</div>
          </div>

        </div>
      </div>
    </div>

    <Footer />

  </div>
</template>

<script>
import { Footer } from '/src/app/shared/components';
import loadjs from 'loadjs';

export default {
  name: 'RegistrationStep1Phone',
  data() {
    return {
      timeLifeDefault: Number(process.env.VUE_APP_TIME_EXPIRE_CODE)*60,

      timeLeft: 0,
      timer: null,
      startTime: null,

      attempts: 2,
      maxAttempts: 2,
      isError: false,
      code: '',
      timerExpire: false,
      isCodeSend: false,
      disableCode: false,

      phoneCodeRules: [
        v => !!v || 'Поле не может быть пустым',
        v => /^\d{4}$/.test(v) || 'Код должен состоять из 4 цифр'
      ],
    };
  },
  components: {
    Footer
  },
  created() {
    let payload = {
      phone: this.registration_data.profile.phone,
      type: 'registration'
    };
    this.$store.dispatch('NEW_CODE_AUTH', payload).then(() => {
    }).catch((message) => {
      console.error('Ошибка отправки кода: ', message);

      this.$buefy.toast.open({
        message: message,
        type: 'is-danger'
      });
    });
  },
  computed: {
    registration_data: {
      get() {
        return this.$store.getters.REGISTRATION_DATA;
      },
      set(value) {
        this.$store.commit('SET_REGISTRATION_DATA', value);
      }
    },
    formattedTime() {
      const minutes = Math.floor(this.timeLeft / 60);
      const seconds = this.timeLeft % 60;
      return `${String(minutes)
          .padStart(2, '0')}:${String(seconds)
          .padStart(2, '0')}`;
    }
  },
  methods: {
    sendCode() {
      if (this.$refs.form_phone_check.validate()) {
        this.isCodeSend = true;

        let payload = {
          payload: {
            phone: this.registration_data.profile.phone,
            code: this.code
          },
          type: 'registration'
        };

        this.$store.dispatch('VERIFICATE_CODE_AUTH', payload).then(() => {
          this.$router.push('/registration-step2');
        }).catch((message) => {
          console.error('Ошибка кода: ', message);
          // неверный
          this.isError = true;
          this.attempts = this.attempts - 1;
          this.isCodeSend = false;
          if (this.attempts === 0) {
            this.isError = false;
            this.disableCode = true;
          }
        });
      } else {
        this.$buefy.toast.open({
          message: "Введите корректный код!",
          type: 'is-danger'
        });
      }
    },
    reSendCode() {
      let payload = {
        phone: this.registration_data.profile.phone,
        type: 'registration'
      };
      this.$store.dispatch('NEW_CODE_AUTH', payload).then(() => {
        this.$buefy.toast.open({
          message: 'Код отправлен',
          type: 'is-success'
        });

        this.attempts = this.maxAttempts;
        this.isCodeSend = false;
        this.isError = false;
        this.timeLeft = this.timeLifeDefault;
        this.startTime = Date.now();
        this.startTimer();
        this.timerExpire = false;
        this.code = '';

      }).catch((message) => {
        console.error('Ошибка отправки кода: ', message);

        this.$buefy.toast.open({
          message: 'Ошибка отправки кода',
          type: 'is-danger'
        });
      });
    },
    errorReset() {
      if (this.isError) {
        this.isError = false;
      }
    },
    startTimer() {
      if (this.timer) {
        cancelAnimationFrame(this.timer);
      }
      const tick = () => {
        const now = Date.now();
        const elapsed = Math.floor((now - this.startTime) / 1000);
        this.timeLeft = this.timeLifeDefault - elapsed;

        if (this.timeLeft > 0) {
          this.timer = requestAnimationFrame(tick);
        } else {
          // Время истекло
          this.timeLeft = 0;
          this.timerExpire = true;
          this.isCodeSend = false;
          this.isError = false;
          this.disableCode = false;
        }
      };

      this.timer = requestAnimationFrame(tick);
    },
    goToLogin() {
      this.$router.push('/login');
    },
    goToHome() {
      this.$router.push('/');
    },
    nextPage() {
      this.$router.push('/registration-step2');
    },
    previousPage() {
      this.$router.push('/registration-step1');
    }
  },
  mounted() {
    /* грузим jquery и webflow */
    loadjs(
        "https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=652921c910bae02d8870dddf",
        {
          success: () => {
            loadjs('./js/webflow.js', {
              success: () => {
              },
              error: () => {
                console.error('Failed to load Webflow');
              }
            });
          },
          error: () => {
            console.error('Failed to load jQuery');
          }
        }
    );

    this.timeLeft = this.timeLifeDefault;
    this.startTime = Date.now();
    this.startTimer();
    this.disableCode = false;

  },
  beforeDestroy() {
    if (this.timer) {
      cancelAnimationFrame(this.timer);
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      window.scrollTo(0, 0);
    });
  },
};
</script>

<style>
</style>
